<div class="container">
  <div nz-row [nzGutter]="[0, 30]">
    <div nz-col [nzSpan]="24">
      <mh-profile-preview
        *ngIf="talent"
        [talent]="talent"
        [trustedView]="true"
        [cvList]="cvList"
        [fileList]="fileList"
        [references]="references"
        [referencesRequest]="referencesRequest"
        [avatarUrl]="avatarUrl"
        [profileEditable]="true"
        [viewerAccountType]="AccountType.ADMIN"
        (talentUpdate)="onTalentUpdate($event)"
        (signInAs)="onSignInAs()"
      ></mh-profile-preview>
    </div>

    <div nz-col [nzSpan]="24">
      <mh-admin-talent-data-spaces [dataSpace]="talent?.dataspace || []"></mh-admin-talent-data-spaces>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="profile-preview__section" id="profile-info">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          {{ "admin.talent-view.section.title.profile-info" | translate }}
        </h2>
        <div nz-row class="profile-preview__section-content">
          <ng-container *ngIf="talent?.updateTimestamp">
            <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
              {{ "profile.update-timestamp.label" | translate }}
            </h4>
            <p nz-col [nzSpan]="24" [nzMd]="18">
              {{ talent?.updateTimestamp | date : "dd.MM.YYYY HH:mm" }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="profile-preview__section" id="comments">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="message" nzTheme="outline"></span>
          {{ "admin.talent-view.section.title.comments" | translate }}
        </h2>
        <mh-account-comments [talentId]="talentId"></mh-account-comments>
      </div>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="profile-preview__section" id="talent-stages">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="solution" nzTheme="outline"></span>
          {{ "admin.talent-view.section.title.talent-stages" | translate }}
        </h2>
        <div class="profile-preview__section-content">
          <nz-table
            *ngIf="talentStages.length"
            #talentStagesTable
            [nzData]="talentStages"
            [nzShowPagination]="false"
            [nzFrontPagination]="false"
          >
            <thead>
              <tr>
                <th>{{ "admin.talent-view.talent-stages.job-opportunity" | translate }}</th>
                <th>{{ "admin.talent-view.talent-stages.stage" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let stage of talentStages">
                <td>
                  <a [routerLink]="['/admin/job-offer-matches', stage.jobOpportunityId]" target="_blank">
                    {{ stage.jobOpportnityName }}
                  </a>
                </td>
                <td>
                  <nz-select
                    nzShowSearch
                    nzAllowClear
                    [nzPlaceHolder]="'admin.stage.placeholder' | translate"
                    [(ngModel)]="stage.stage"
                    (ngModelChange)="onSetTalentStage(stage.jobOpportunityId, $event || 0)"
                    style="width: 200px"
                  >
                    <nz-option
                      *ngFor="let option of talentStageOptions"
                      [nzLabel]="option.name | translate"
                      [nzValue]="option.value"
                    ></nz-option>
                  </nz-select>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24" class="hide-empty-comments-from-jo">
      <div class="profile-preview__section" id="comments-from-jo">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="message" nzTheme="outline"></span>
          {{ "admin.talent-view.section.title.comments-from-jo" | translate }}
        </h2>
        <div class="profile-preview__section-content">
          <mh-account-comments-from-jo [talentId]="talentId"></mh-account-comments-from-jo>
        </div>
      </div>
    </div>
  </div>
</div>
