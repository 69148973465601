import { Inject, Injectable } from "@angular/core";
import { AccountApi } from "./account.api";
import { map, Observable } from "rxjs";
import { AccountSerializer } from "./account.serializer";
import {
  AccountFilter,
  AccountId,
  ActiveConversation,
  ActiveConversationDTO,
  AdminAccount,
  AdminAccountDTO,
  Comment,
  CommentFromJo,
  JobOpportunityBasicInfo,
  MissionPartnerAccount,
  MissionPartnerAccountDTO,
  MissionPartnerBasicInfo,
  Negotiation,
  NegotiationDTO,
  TalentAccount,
  TalentAccountDTO,
  HiringDashboardData,
} from "./account.types";
import { PaginatedContent } from "../../../api/common.types";
import { AccountType } from "../../login-page/login-page-data/login.types";
import { AppConfig } from "../../../../../../core/src/lib/app-config/config.types";
import { APP_CONFIGURATION } from "../../../../../../core/src/lib/core.di";
import { ProfileSerializer } from "../../../api/profile/profile.serializer";
import { MissionPartner, Talent } from "../../../api/sign-up/signup.types";
import { Reference, ReferenceRequest } from "../../../api/references/references.types";
import { ReferencesSerializer } from "../../../api/references/references.serializer";
import {
  JobOffer,
  JobOfferTalentComment,
  MatchedTalent,
  MatchedTalentDTO,
} from "../../../api/job-offer/job-offer.types";
import { JobOfferSerializer } from "../../../api/job-offer/job-offer.serializer";
import { AdminTalentSerializer } from "../../../api/admin-talent/admin-talent.serializer";
import { PageName } from "../../../common-componnets/common-types";
import { AgreementTerms } from "../../../api/messages/messages.types";
@Injectable({ providedIn: "root" })
export class AccountService {
  private readonly loginByTokenUrl: string;

  constructor(
    private readonly accountApi: AccountApi,
    private readonly accountSerializer: AccountSerializer,
    private readonly referencesSerializer: ReferencesSerializer,
    private readonly jobOfferSerializer: JobOfferSerializer,
    private profileSerializer: ProfileSerializer,
    private adminTalentSerializer: AdminTalentSerializer,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    this.loginByTokenUrl = this.appConfig.loginByTokenUrl;
  }

  loadTalentList(filter: AccountFilter): Observable<PaginatedContent<TalentAccount>> {
    return this.accountApi.loadTalentList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.accountSerializer.deserializeTalentAccounts(
            paginatedContent.items.slice(0, 100) as Array<TalentAccountDTO>, //slice is safeguard, we should not handle more than 100 items
          ),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
          additionalData: {
            statistic: this.adminTalentSerializer.deserializeTalentListStatistic(
              paginatedContent.additionalData.statistic,
            ),
          },
        };
      }),
    );
  }

  loadMissionPartnerList(filter: AccountFilter): Observable<PaginatedContent<MissionPartnerAccount>> {
    return this.accountApi.loadMissionPartnerList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.accountSerializer.deserializeMissionPartnerAccounts(
            paginatedContent.items as Array<MissionPartnerAccountDTO>,
          ),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  loadUserAdminList(filter: AccountFilter): Observable<PaginatedContent<AdminAccount>> {
    return this.accountApi.loadUserAdminList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.accountSerializer.deserializeUserAdminAccounts(paginatedContent.items as Array<AdminAccountDTO>),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  getAdminList(): Observable<AdminAccount[]> {
    return this.accountApi
      .getAdminList()
      .pipe(map((admins) => this.accountSerializer.deserializeUserAdminAccounts(admins)));
  }

  updateAdmin(oldEmail: string, email: string, name: string): Observable<boolean> {
    return this.accountApi.updateAdmin(oldEmail, email, name);
  }

  createAdmin(email: string, password: string, name: string): Observable<boolean> {
    return this.accountApi.createAdmin(email, password, name);
  }

  loadActiveConversationsList(filter: AccountFilter): Observable<PaginatedContent<ActiveConversation>> {
    return this.accountApi.loadActiveConversationsList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.accountSerializer.deserializeActiveConversations(
            paginatedContent.items as Array<ActiveConversationDTO>,
          ),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  loadNegotiationsList(filter: AccountFilter): Observable<PaginatedContent<Negotiation>> {
    return this.accountApi.loadNegotiationsList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.accountSerializer.deserializeNegotiations(paginatedContent.items as Array<NegotiationDTO>),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  loadJobOffer(jobOfferId: string): Observable<JobOffer> {
    return this.accountApi
      .loadJobOffer(jobOfferId)
      .pipe(map((dto) => this.jobOfferSerializer.deserializeJobOffer(dto)));
  }

  loadJobOfferMatches(filter: AccountFilter): Observable<PaginatedContent<MatchedTalent>> {
    return this.accountApi.loadJobOfferMatches(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.jobOfferSerializer.deserializeMatchedTalents(paginatedContent.items as Array<MatchedTalentDTO>),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  loadJobOfferMatchesReindex(jobOfferId: string): Observable<boolean> {
    return this.accountApi.loadJobOfferMatchesReindex(jobOfferId);
  }

  sendPasswordResetEmail(accountId: AccountId, email: string): Observable<boolean> {
    return this.accountApi.sendPasswordResetEmail(accountId, email);
  }

  updatePassword(accountId: string, password: string): Observable<boolean> {
    return this.accountApi.updatePassword({ internalId: accountId }, password);
  }

  deleteAccount(accountId: AccountId, accountType: AccountType): Observable<boolean> {
    return this.accountApi.deleteAccount(accountId, accountType);
  }

  reviewMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.reviewMissionPartner(accountId);
  }

  discardReviewMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.discardReviewMissionPartner(accountId);
  }

  setSelfServiceStatusMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.setSelfServiceStatusMissionPartner(accountId);
  }

  removeSelfServiceStatusMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.removeSelfServiceStatusMissionPartner(accountId);
  }

  setPassiveStatusMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.setPassiveStatusMissionPartner(accountId);
  }

  removePassiveStatusMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.removePassiveStatusMissionPartner(accountId);
  }

  setPremiumStatusMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.setPremiumStatusMissionPartner(accountId);
  }

  removePremiumStatusMissionPartner(accountId: string): Observable<boolean> {
    return this.accountApi.removePremiumStatusMissionPartner(accountId);
  }

  setStageMissionPartner(missionPartnerId: string, stage: number): Observable<boolean> {
    return this.accountApi.setStageMissionPartner(missionPartnerId, stage);
  }

  downloadData(accountId: AccountId, accountType: AccountType, fileName: string): Observable<void> {
    return this.accountApi.downloadData(accountId, accountType, fileName);
  }

  downloadTalentListCSV(fileName: string): Observable<void> {
    return this.accountApi.downloadTalentListCSV(fileName);
  }

  downloadMissionPartnerListCSV(fileName: string): Observable<void> {
    return this.accountApi.downloadMissionPartnerListCSV(fileName);
  }

  signInAs(accountId: string, page?: PageName, pageParams?: { [PageParam: string]: string }): void {
    const base64Params = btoa(JSON.stringify({ page, pageParams }));
    const pageParam = page ? `&forwardPage=${base64Params}` : "";

    this.accountApi.signInAs(accountId).subscribe((token) => {
      window.open(this.loginByTokenUrl + token + pageParam, "_blank");
    });
  }

  getTalentProfile(accountId: string): Observable<Talent> {
    return this.accountApi
      .getTalentProfile(accountId)
      .pipe(map((dto) => this.profileSerializer.deserializeTalentWithDataSpaces(dto)));
  }

  getMissionPartnerProfile(accountId: string): Observable<MissionPartner> {
    return this.accountApi
      .getMissionPartnerProfile(accountId)
      .pipe(map((dto) => this.profileSerializer.deserializeMissionPartnerWithDataSpaces(dto)));
  }

  loadAccountComments(targetId: string): Observable<Array<Comment>> {
    return this.accountApi
      .loadAccountComments(targetId)
      .pipe(map((dto) => this.accountSerializer.deserializeComments(dto)));
  }

  loadAccountCommentsFromJobOpportunities(targetId: string): Observable<Array<CommentFromJo>> {
    return this.accountApi
      .loadAccountCommentsFromJobOpportunities(targetId)
      .pipe(map((dto) => this.accountSerializer.deserializeCommentsFromJo(dto)));
  }

  sendAccountComment(targetId: string, text: string, authorId: string): Observable<Comment> {
    return this.accountApi
      .sendAccountComment(targetId, text, authorId)
      .pipe(map((dto) => this.accountSerializer.deserializeComment(dto)));
  }

  updateAccountComment(commentId: string, text: string): Observable<Comment> {
    return this.accountApi
      .updateAccountComment(commentId, text)
      .pipe(map((dto) => this.accountSerializer.deserializeComment(dto)));
  }

  loadJobOfferTalentComments(jobOfferId: string, talentId: string): Observable<Array<JobOfferTalentComment>> {
    return this.accountApi
      .loadJobOfferTalentComments(jobOfferId, talentId)
      .pipe(map((dto) => this.jobOfferSerializer.deserializeComments(dto)));
  }

  sendJobOfferTalentComment(jobOfferId: string, talentId: string, text: string): Observable<JobOfferTalentComment> {
    return this.accountApi
      .sendJobOfferTalentComment(jobOfferId, talentId, text)
      .pipe(map((dto) => this.jobOfferSerializer.deserializeComment(dto)));
  }

  updateJobOfferTalentComment(commentId: string, text: string): Observable<JobOfferTalentComment> {
    return this.accountApi
      .updateJobOfferTalentComment(commentId, text)
      .pipe(map((dto) => this.jobOfferSerializer.deserializeComment(dto)));
  }

  deleteJobOfferTalentComment(jobOfferId: string, commentId: string): Observable<boolean> {
    return this.accountApi.deleteJobOfferTalentComments(jobOfferId, commentId);
  }

  loadReferencesById(talentId: string): Observable<Array<Reference>> {
    return this.accountApi
      .loadReferencesById(talentId)
      .pipe(map((dto) => this.referencesSerializer.deserializeReferences(dto)));
  }

  loadReferencesRequestById(talentId: string): Observable<Array<ReferenceRequest>> {
    return this.accountApi
      .loadReferencesRequestById(talentId)
      .pipe(map((dto) => this.referencesSerializer.deserializeReferencesRequest(dto)));
  }

  updateTalent(talent: Talent): Observable<boolean> {
    return this.accountApi.updateTalent(this.profileSerializer.serializeTalent(talent));
  }

  changeInvoiceStatus(invoiceId: string, status: string): Observable<boolean> {
    return this.accountApi.changeInvoiceStatus(invoiceId, status);
  }

  exportTalentProfileToBrevo(talentId: string): Observable<boolean> {
    return this.accountApi.exportTalentProfileToBrevo(talentId);
  }

  exportMissionPartnerProfileToBrevo(missionPartnerId: string): Observable<boolean> {
    return this.accountApi.exportMissionPartnerProfileToBrevo(missionPartnerId);
  }

  setCommunityMemberStatus(talentId: string, status: boolean): Observable<boolean> {
    return this.accountApi.setCommunityMemberStatus(talentId, status);
  }

  addAccountConfirmedStatus(accountId: string): Observable<boolean> {
    return this.accountApi.addAccountConfirmedStatus(accountId);
  }

  deleteAccountComment(commentId: string): Observable<boolean> {
    return this.accountApi.deleteAccountComment(commentId);
  }

  getMissionPartnersWithActiveJobOffers(): Observable<Array<MissionPartnerBasicInfo>> {
    return this.accountApi.getMissionPartnersWithActiveJobOffers();
  }

  getMissionPartners(): Observable<Array<MissionPartnerBasicInfo>> {
    return this.accountApi.getMissionPartners();
  }

  getJobOpportunitiesForMissionPartnerWithoutTalent(
    missionPartnerId: string,
    talentId: string,
  ): Observable<JobOpportunityBasicInfo[]> {
    return this.accountApi.getJobOpportunitiesForMissionPartnerWithoutTalent(missionPartnerId, talentId);
  }

  getJobOpportunitiesForMissionPartner(missionPartnerId: string): Observable<JobOpportunityBasicInfo[]> {
    return this.accountApi.getJobOpportunitiesForMissionPartner(missionPartnerId);
  }

  addTalentToJobOffer(talentId: string, jobOfferId: string): Observable<boolean> {
    return this.accountApi.addTalentToJobOffer(talentId, jobOfferId);
  }

  getHiringDashboard(): Observable<HiringDashboardData> {
    return this.accountApi.getHiringDashboard();
  }

  getHiringDashboardWithFilters(missionPartnerId: string, jobOpportunityId: string): Observable<HiringDashboardData> {
    return this.accountApi.getHiringDashboardWithFilters(missionPartnerId, jobOpportunityId);
  }

  getHiringTerms(conversationId: string): Observable<AgreementTerms> {
    return this.accountApi.getHiringTerms(conversationId);
  }

  setHiringReminder(talentId: string, jobOpportunityId: string, reminderDate: number): Observable<boolean> {
    return this.accountApi.setHiringReminder(talentId, jobOpportunityId, reminderDate);
  }

  deleteHiringReminder(talentId: string, jobOpportunityId: string): Observable<boolean> {
    return this.accountApi.deleteHiringReminder(talentId, jobOpportunityId);
  }

  getMatchCriteriaExplanation(jobOpportunityId: string, accountId: string): Observable<string> {
    return this.accountApi.getMatchCriteriaExplanation(jobOpportunityId, accountId);
  }
}
