<nz-page-header nzTitle="{{ 'profile.desired-job.sub-title' | translate }}"></nz-page-header>
<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <ng-container formGroupName="desiredJob">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.dev-path.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="developmentPath" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of developmentPath$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.wish-sector.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzMode="multiple"
              nzSize="default"
              formControlName="wishSector"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of sector$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.wish-industry.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzSize="default"
              nzMode="multiple"
              formControlName="wishIndustry"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of wishIndustry$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.wish-department.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzMode="multiple"
              nzSize="default"
              formControlName="wishDepartment"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of departmentOptions$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label>
            <!-- 'profile.preferred-working-language.label' -->
            <mh-explanation-label [labelKey]="'profile.preferred-language.title'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzMode="multiple"
              [nzPlaceHolder]="'select.placeholder' | translate"
              formControlName="preferredWorkingLanguage"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                *ngFor="let option of languageOptions$ | async"
                [nzLabel]="option.name | translate"
                [nzValue]="option"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24">
        <mh-explanation-label [labelKey]="'profile.chose-company-type.title'" [divider]="true"></mh-explanation-label>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-label class="p-lr-0">
          <mh-explanation-label [labelKey]="'profile.chose-from-list.label'"></mh-explanation-label>
        </nz-form-label>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-label class="p-lr-0">
          <mh-explanation-label [labelKey]="'profile.provide.label'"></mh-explanation-label>
        </nz-form-label>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-control>
            <nz-select
              nzMode="multiple"
              [nzPlaceHolder]="'select.placeholder' | translate"
              formControlName="desiredCompanyType"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                *ngFor="let option of companyTypeOptions$ | async"
                [nzLabel]="option.name | translate"
                [nzValue]="option"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-control>
            <input
              nz-input
              nzSize="default"
              formControlName="desiredCompanyTypeText"
              (ngModelChange)="onOwnCompanyChange()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.leadership-responsibility.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select formControlName="leadershipResponsibility" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of leadershipResponsibility$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>

    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.salary-expectation.label'" [divider]="true"></mh-explanation-label>
    </div>

    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-control>
          <mh-salary-expectation-input
            formControlName="salaryExpectations"
            [validators]="formGroup.get('salaryExpectations')?.validator"
          ></mh-salary-expectation-input>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.desired-schedule.sub-title'" [divider]="true"></mh-explanation-label>
    </div>

    <ng-container formGroupName="desiredSchedule">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.whours-per-week.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzSize="default"
              nzMode="multiple"
              formControlName="workingHoursPerWeek"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of weekWorkingHoursOptions$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.planned-whours-per-week.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzSize="default"
              nzMode="multiple"
              formControlName="workingHoursPerWeekPlanned"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of weekWorkingHoursOptions$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.planned-slots.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="plannedSlots" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of slotOptions$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>

    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.desired-place.sub-title'" [divider]="true"></mh-explanation-label>
    </div>

    <ng-container formGroupName="desiredPlaceOfWork">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.ideal-local-assignments.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzSize="default"
              nzMode="multiple"
              formControlName="idealWorkingAssignments"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of workAssignmentOptions$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.ideal-location.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <mh-location-input
              nzSize="default"
              formControlName="idealWorkingLocation"
              [validators]="formGroup.get('desiredPlaceOfWork.idealWorkingLocation')?.validator"
              (ngModelChange)="onIdealWorkingLocationChange()"
            ></mh-location-input>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>

    <ng-container formGroupName="desiredJob">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.ideal-local-distance.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              nzSize="default"
              formControlName="idealWorkingLocationDistance"
              nzAllowClear
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of radiusOptions$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>

    <ng-container formGroupName="desiredPlaceOfWork">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.travel-willingness.label'">
              {{ "profile.travel-willingness.label" | translate }} <b>{{ travelWillingness }}</b>
            </mh-explanation-label>
          </nz-form-label>
          <div nz-row>
            <div nz-col [nzSpan]="22" nzOffset="1" [nzMd]="{ span: 24, offset: 0 }">
              <nz-form-control>
                <nz-slider
                  [nzMin]="1"
                  [nzMax]="5"
                  [nzMarks]="marksReadiness"
                  nzTooltipVisible="never"
                  formControlName="travelWillingness"
                ></nz-slider>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
      </div>
    </ng-container>

    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.job-sharing.title'" [divider]="true"></mh-explanation-label>
    </div>
    <ng-container formGroupName="desiredJob">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="openForTandem" (nzCheckedChange)="onCheckedChange($event)">
              <span>{{ "profile.open-for-tandem.checkbox" | translate }}</span>
            </label>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'profile.open-for-tandem-reason.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input nzSize="default" formControlName="openForTandemReason" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{ "save-and-next.button" | translate }}</button>
    </div>
  </div>
</form>
