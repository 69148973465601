import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { HiringDashboardComponent } from "./hiring-dashboard.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSelectModule } from "ng-zorro-antd/select";
import { RouterLink } from "@angular/router";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzFormModule } from "ng-zorro-antd/form";
import { ReminderDialogComponent } from "./reminder-dialog/reminder-dialog.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [HiringDashboardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NzSpinModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    RouterLink,
    NzDatePickerModule,
    NzModalModule,
    NzFormModule,
    ReminderDialogComponent,
    DragDropModule,
  ],
  exports: [HiringDashboardComponent],
})
export class HiringDashboardModule {}
