import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatchCriteriaDialogComponent } from "./match-criteria-dialog.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzButtonModule } from "ng-zorro-antd/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { TranslateModule } from "@ngx-translate/core";
import { NzSpinModule } from "ng-zorro-antd/spin";

@NgModule({
  declarations: [MatchCriteriaDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzModalModule,
    NzButtonModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    NzSpinModule,
    ReactiveFormsModule,
  ],
  exports: [MatchCriteriaDialogComponent],
})
export class MatchCriteriaDialogModule {}
