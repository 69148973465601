import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { MissionPartner } from "../../../../../../api/sign-up/signup.types";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { PresentationMedia } from "../../../../../../../../../core/src/lib/recording/recording.types";
import { UrlValidator } from "@momhunting/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-profile-about",
  templateUrl: "./profile-about.component.html",
  styleUrls: ["./profile-about.component.less"],
})
export class ProfileAboutComponent implements OnInit, OnDestroy, OnChanges {
  @Input() missionPartner!: MissionPartner;
  @Input() mobileView!: boolean;

  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() submitChange = new EventEmitter<boolean>();
  @Output() profileMediaChange = new EventEmitter<PresentationMedia | null>();

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant("field.required.label"),
      invalid: this.translateService.instant("field.invalid.label"),
    },
  };

  public formGroup!: FormGroup;
  public avatarUrl!: string;
  public isEditingCompanyName = false;
  private readonly destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["missionPartner"]) {
      if (this.missionPartner.avatarUrl) {
        this.avatarUrl = this.missionPartner.avatarUrl;
      }
    }
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      companyName: [this.missionPartner.companyName, []],
      address: [this.missionPartner.address, [Validators.required]],
      street: [this.missionPartner.street, [Validators.required]],
      houseNumber: [this.missionPartner.houseNumber, [Validators.required]],
      contactPerson: this.fb.group({
        title: [this.missionPartner.contactPerson?.title, []],
        firstName: [this.missionPartner.contactPerson?.firstName, [Validators.required]],
        lastName: [this.missionPartner.contactPerson?.lastName, [Validators.required]],
        department: [this.missionPartner.contactPerson?.department, []],
      }),
      welcomeMessage: [this.missionPartner.welcomeMessage, []],
      profilePresentationExternalLink: [this.missionPartner.profilePresentationExternalLink, [UrlValidator]],
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.missionPartner,
        ...value,
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  editCompanyName() {
    this.isEditingCompanyName = true;
  }

  saveCompanyName() {
    if (!this.isStepValid()) return;
    this.isEditingCompanyName = false;
    this.submitForm(true);
  }

  submitForm(skipNavigationToNextStep?: boolean) {
    if (!this.isStepValid()) return;
    this.submitChange.emit(skipNavigationToNextStep);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateProfileMedia(event: PresentationMedia | null) {
    this.profileMediaChange.emit(event);
  }
}
