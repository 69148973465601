<nz-page-header [nzTitle]="'mp.profile.about.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" [nzAutoTips]="autoTips" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" class="col-avatar">
      <mh-avatar-upload [avatarUrl]="avatarUrl" [isLogo]="true"></mh-avatar-upload>
      <ng-container *ngIf="!isEditingCompanyName">
        <div class="company-name">
          {{ missionPartner.companyName }}
          <button nz-button nzType="link" class="edit-button" (click)="editCompanyName()">
            <span nz-icon nzType="edit"></span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="isEditingCompanyName">
        <nz-form-item class="company-name-edit">
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.company-name.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="companyName" />
          </nz-form-control>
        </nz-form-item>
        <div class="company-name-edit-buttons">
          <button nz-button nzType="primary" (click)="saveCompanyName()">{{ "save.button" | translate }}</button>
        </div>
      </ng-container>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'mp.profile.address.label'" [divider]="true"></mh-explanation-label>
    </div>
    <div nz-col [nzSpan]="24">
      <mh-location-input
        [showLabels]="true"
        formControlName="address"
        [validators]="formGroup.get('address')?.validator"
        [mark]="formGroup.get('address')?.dirty || false"
      ></mh-location-input>
    </div>

    <div nz-col [nzSpan]="24">
      <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="16" [nzSm]="18">
          <nz-form-item>
            <nz-form-label nzRequired>
              <mh-explanation-label [labelKey]="'reg.mp.street-name.label'"></mh-explanation-label>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="street" [placeholder]="'input.placeholder' | translate" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8" [nzSm]="6">
          <nz-form-item>
            <nz-form-label nzRequired>
              <mh-explanation-label [labelKey]="'reg.mp.street-number.label'"></mh-explanation-label>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="houseNumber" [placeholder]="'input.placeholder' | translate" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'mp.profile.info.label'" [divider]="true"></mh-explanation-label>
    </div>
    <ng-container formGroupName="contactPerson">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'">
            <mh-explanation-label [labelKey]="'mp.profile.title.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input [placeholder]="'mp.profile.title.placeholder' | translate" formControlName="title" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
            <mh-explanation-label [labelKey]="'mp.profile.firstname.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input
              nz-input
              [placeholder]="'mp.profile.firstname.placeholder' | translate"
              formControlName="firstName"
            />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
            <mh-explanation-label [labelKey]="'mp.profile.lastname.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input [placeholder]="'mp.profile.lastname.placeholder' | translate" formControlName="lastName" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'">
            <mh-explanation-label [labelKey]="'mp.profile.department.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input
              nz-input
              [placeholder]="'mp.profile.department.placeholder' | translate"
              formControlName="department"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>

  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label>
        <mh-explanation-label [labelKey]="'mp.profile.welcome-message.label'"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control>
        <textarea rows="4" formControlName="welcomeMessage" nz-input></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div nz-col [nzSpan]="24">
    <mh-explanation-label [labelKey]="'mp.profile.media.label'" [divider]="true"></mh-explanation-label>
    <mh-profile-media-upload
      [presentationMedia]="missionPartner.presentationMedia"
      [mobileView]="mobileView"
      (profileMediaChange)="updateProfileMedia($event)"
    ></mh-profile-media-upload>
  </div>

  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'">
        <mh-explanation-label [labelKey]="'mp.profile.media-link.label'"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control [nzSpan]="24" [nzErrorTip]="urlErrorTpl">
        <input
          nz-input
          [placeholder]="'mp.profile.media-link.placeholder' | translate"
          formControlName="profilePresentationExternalLink"
        />
      </nz-form-control>
      <ng-template #urlErrorTpl let-control>
        <ng-container *ngIf="control.hasError('url')">{{ "field.invalid-url.label" | translate }}</ng-container>
      </ng-template>
    </nz-form-item>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <p class="required-explanation">{{ "reg.required.explanation" | translate }}</p>
    </div>

    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{ "save-and-next.button" | translate }}</button>
    </div>
  </div>
</form>
