<nz-list *ngIf="commentsFromJobOpportunities.length" [nzItemLayout]="'horizontal'">
  <nz-comment
    *ngFor="let comment of commentsFromJobOpportunities"
    [nzAuthor]="comment.authorName"
    [nzDatetime]="comment.creationTimestamp"
  >
    <nz-comment-content>
      <div class="comment">
        <div class="comment__body">
          <div class="comment__job-opportunity">
            <h4>
              <a [routerLink]="['/admin/job-offer-matches', comment.jobOpportunityId]">
                {{ comment.jobOpportunityName }}
              </a>
            </h4>
            <p *ngIf="comment.stage">{{ "admin.comment.stage" | translate }}: {{ comment.stage.name | translate }}</p>
          </div>
          <p *ngIf="comment.missionPartnerId">
            <span>{{ "admin.comment.mission-partner" | translate }}:</span>
            <a [routerLink]="['/admin/mission-partner-view', comment.missionPartnerId]">
              {{ comment.missionPartnerName }}
            </a>
          </p>
          <p *ngIf="comment.forMissionPartnerId">
            <span>{{ "admin.comment.for-mission-partner" | translate }}:</span>
            <a [routerLink]="['/admin/mission-partner-view', comment.forMissionPartnerId]">
              {{ comment.forMissionPartnerName }}
            </a>
          </p>
          <p class="comment__text">{{ comment.text }}</p>
        </div>
        <div class="comment__actions">
          <button
            *ngIf="!editingCommentId || editingCommentId === comment.commentId"
            class="comment__edit-btn"
            nz-button
            nzType="link"
            nzShape="circle"
            (click)="startEditing(comment)"
          >
            <span nz-icon nzType="edit" nzTheme="outline"></span>
          </button>
          <button
            *ngIf="editingCommentId === comment.commentId"
            class="comment__cancel-btn"
            nz-button
            nzType="link"
            nzShape="circle"
            (click)="cancelEditing()"
          >
            <span nz-icon nzType="close" nzTheme="outline"></span>
          </button>
          <button
            *ngIf="!editingCommentId"
            class="comment__delete-btn"
            nz-button
            nzType="link"
            nzShape="circle"
            (click)="confirmDeleteComment(comment)"
          >
            <span nz-icon nzType="delete" nzTheme="outline"></span>
          </button>
        </div>
      </div>
    </nz-comment-content>
  </nz-comment>
</nz-list>

<nz-comment *ngIf="editingCommentId">
  <nz-comment-content>
    <form nz-form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="textErrorTpl">
          <textarea nz-input rows="4" formControlName="text"></textarea>
          <ng-template #textErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">{{
              "admin.comment.error.required" | translate
            }}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="submit-btn">
        <button nz-button nzType="primary">
          {{ "admin.comment.button.update" | translate }}
        </button>
      </nz-form-item>
    </form>
  </nz-comment-content>
</nz-comment>
