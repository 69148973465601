import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminTalentViewComponent } from "./admin-talent-view.component";
import { TranslateModule } from "@ngx-translate/core";
import { AccountCommentsModule } from "../../../../../../../common-ui/src/lib/account-comments/account-comments.module";
import { AccountCommentsFromJoModule } from "../../../../../../../common-ui/src/lib/account-comments-from-jo/account-comments-from-jo.module";
import { AdminTalentDataspacesModule } from "../../../../common-componnets/admin-talent-dataspaces/admin-talent-dataspaces.module";
import { ProfilePreviewModule } from "../../../../common-componnets/profile-view/profile-preview.module";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [AdminTalentViewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AccountCommentsModule,
    AccountCommentsFromJoModule,
    AdminTalentDataspacesModule,
    ProfilePreviewModule,
    NzGridModule,
    NzCardModule,
    NzIconModule,
    NzTableModule,
    NzSelectModule,
    FormsModule,
    RouterModule,
  ],
})
export class AdminTalentViewModule {}
