import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../../../home-admin-data/account.service";
import { StrictEmailValidator } from "@momhunting/core";

@Component({
  selector: "mh-admin-user-admin-create",
  templateUrl: "./admin-user-admin-create.component.html",
  styleUrls: ["./admin-user-admin-create.component.less"],
})
export class AdminUserAdminCreateComponent implements OnInit {
  validateForm!: UntypedFormGroup;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private notificationService: NzNotificationService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, StrictEmailValidator]],
      password: ["", [Validators.required]],
    });
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(): void {
    if (this.validateForm.valid) {
      const formValue = this.validateForm.value;
      this.accountService.createAdmin(formValue.email, formValue.password, formValue.name).subscribe((success) => {
        if (success) {
          this.notificationService.success("", this.translateService.instant("admin.user-admin.create.success"));
          this.modal.close("success");
        } else {
          this.notificationService.error("", this.translateService.instant("notification.error"));
        }
      });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
