import { Component, OnInit } from "@angular/core";
import { AccountService } from "../../home-admin-data/account.service";
import {
  HiringDashboardData,
  MissionPartnerBasicInfo,
  JobOpportunityBasicInfo,
} from "../../home-admin-data/account.types";
import { firstValueFrom, take } from "rxjs";
import { NzModalService } from "ng-zorro-antd/modal";
import { ReminderDialogComponent } from "./reminder-dialog/reminder-dialog.component";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { AdminJobOfferService } from "../../../../api/admin-job-offer/admin-job-offer.service";

interface HiringDashboardFilters {
  missionPartnerId: string | null;
  jobOpportunityId: string | null;
}

const FILTERS_PAGE = FiltersPage.HIRING_DASHBOARD;

@Component({
  selector: "mh-hiring-dashboard",
  templateUrl: "./hiring-dashboard.component.html",
  styleUrls: ["./hiring-dashboard.component.less"],
})
export class HiringDashboardComponent implements OnInit {
  dashboardData?: HiringDashboardData;
  loading = true;
  missionPartners: MissionPartnerBasicInfo[] = [];
  jobOpportunities: JobOpportunityBasicInfo[] = [];
  filterForm!: UntypedFormGroup;

  constructor(
    private accountService: AccountService,
    private modalService: NzModalService,
    private filterStorageService: FilterStorageService,
    private fb: UntypedFormBuilder,
    private adminJobOfferService: AdminJobOfferService,
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      missionPartnerId: [null],
      jobOpportunityId: [null],
    });

    this.loadMissionPartners();
    this.setupFilterSubscriptions();
    this.restoreFilters();
  }

  private async restoreFilters() {
    const filters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE];
    const storedFilters = filters as unknown as HiringDashboardFilters | undefined;

    if (storedFilters?.missionPartnerId) {
      try {
        this.filterForm.patchValue({ missionPartnerId: storedFilters.missionPartnerId }, { emitEvent: false });

        const opportunities = await firstValueFrom(this.loadJobOpportunities(storedFilters.missionPartnerId));
        this.jobOpportunities = opportunities;

        if (
          storedFilters.jobOpportunityId &&
          this.jobOpportunities.some((job) => job.id === storedFilters.jobOpportunityId)
        ) {
          this.filterForm.patchValue({ jobOpportunityId: storedFilters.jobOpportunityId }, { emitEvent: false });
        }
      } catch (error) {
        console.error("Error restoring filters:", error);
      }
    }

    this.fetchDashboardData();
  }

  private loadJobOpportunities(missionPartnerId: string) {
    return this.accountService.getJobOpportunitiesForMissionPartner(missionPartnerId).pipe(take(1));
  }

  private setupFilterSubscriptions() {
    const missionPartnerControl = this.filterForm.get("missionPartnerId");
    missionPartnerControl?.valueChanges.subscribe((missionPartnerId: string | null) => {
      this.filterForm.patchValue({ jobOpportunityId: null }, { emitEvent: false });
      this.jobOpportunities = [];

      if (missionPartnerId) {
        this.loadJobOpportunities(missionPartnerId).subscribe((opportunities) => {
          this.jobOpportunities = opportunities;
        });
      }
    });
  }

  loadMissionPartners() {
    this.accountService
      .getMissionPartnersWithActiveJobOffers()
      .pipe(take(1))
      .subscribe((partners) => {
        this.missionPartners = partners;
      });
  }

  submitFilterForm() {
    this.filterStorageService.saveFilters(this.filterForm, FILTERS_PAGE);
    this.fetchDashboardData();
  }

  private sortColumnTalents(talents: any[]): any[] {
    return talents.sort((a, b) => {
      const aDate = a.reminderDate ? new Date(a.reminderDate).getTime() : Number.MAX_SAFE_INTEGER;
      const bDate = b.reminderDate ? new Date(b.reminderDate).getTime() : Number.MAX_SAFE_INTEGER;

      if (aDate !== bDate) {
        return aDate - bDate;
      }

      return a.talentName.localeCompare(b.talentName);
    });
  }

  sortPredicate = (a: any, b: any) => {
    return false;
  };

  fetchDashboardData() {
    this.loading = true;
    const formValue = this.filterForm.getRawValue() as unknown as HiringDashboardFilters;
    const { missionPartnerId, jobOpportunityId } = formValue;

    const request = missionPartnerId
      ? this.accountService.getHiringDashboardWithFilters(missionPartnerId, jobOpportunityId || "")
      : this.accountService.getHiringDashboard();

    request.pipe(take(1)).subscribe((data) => {
      // Sort talents in each column
      data.columns.forEach((column) => {
        column.talents = this.sortColumnTalents(column.talents);
      });
      this.dashboardData = data;
      this.loading = false;
    });
  }

  openReminderDialog(talent: any) {
    this.modalService
      .create({
        nzContent: ReminderDialogComponent,
        nzData: {
          data: talent,
        },
        nzFooter: null,
      })
      .afterClose.subscribe((result) => {
        if (result === "remove") {
          this.deleteReminder(talent);
        } else if (result instanceof Date) {
          this.setReminder(talent, result);
        }
      });
  }

  setReminder(talent: any, date: Date) {
    this.accountService
      .setHiringReminder(talent.accountId, talent.jobOpportunityId, date.getTime())
      .pipe(take(1))
      .subscribe(() => {
        this.fetchDashboardData();
      });
  }

  deleteReminder(talent: any) {
    this.accountService
      .deleteHiringReminder(talent.accountId, talent.jobOpportunityId)
      .pipe(take(1))
      .subscribe(() => {
        this.fetchDashboardData();
      });
  }

  isPastDate(date: string | number): boolean {
    return new Date(date).getTime() < new Date().setHours(0, 0, 0, 0);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

      const dragData = event.item.data;
      const talent = dragData.talent;
      const newStage = this.dashboardData?.columns.find((col) => col.talents === event.container.data)?.id;

      if (this.dashboardData) {
        this.dashboardData.columns.forEach((column) => {
          column.talents = this.sortColumnTalents(column.talents);
        });
      }

      if (talent && newStage !== undefined) {
        this.adminJobOfferService
          .setTalentStage(talent.jobOpportunityId, talent.accountId, newStage)
          .pipe(take(1))
          .subscribe({
            next: () => {
              talent.stage = newStage;
            },
            error: () => {
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );

              if (this.dashboardData) {
                this.dashboardData.columns.forEach((column) => {
                  column.talents = this.sortColumnTalents(column.talents);
                });
              }
            },
          });
      }
    }
  }
}
