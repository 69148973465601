<nz-page-header class="large p-r-0">
  <nz-page-header-title>{{ "assessment.main.title" | translate }}</nz-page-header-title>
</nz-page-header>

<div nz-row>
  <div *ngIf="!assessment" nz-col nzSpan="24">
    <nz-alert nzType="info" [nzMessage]="'mp.ma-initial.status' | translate" nzShowIcon></nz-alert>
  </div>
  <div *ngIf="assessment && assessment.status === MaturityAssessmentStatus.ACTIVE" nz-col nzSpan="24">
    <nz-alert class="m-b-16" nzType="info" [nzMessage]="'mp.ma-active.status' | translate" nzShowIcon></nz-alert>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-input-group class="m-b-10" nzSearch [nzAddOnAfter]="suffixCopyButton">
          <input type="text" nz-input [value]="assessment.link" />
        </nz-input-group>
      </div>
    </div>
    <ng-template #suffixCopyButton>
      <button nz-button nzType="primary" nzSearch [cdkCopyToClipboard]="assessment.link" (click)="onCopyToClipboard()">
        <ng-container *ngIf="!copied"> <span nz-icon nzType="copy"></span> copy </ng-container>
        <ng-container *ngIf="copied"> <span nz-icon nzType="file-done"></span> copied </ng-container>
      </button>
    </ng-template>
  </div>
  <div *ngIf="assessment && assessment.status === MaturityAssessmentStatus.COMPLETED" nz-col nzSpan="24">
    <p>
      <nz-alert nzType="info" [nzMessage]="'mp.ma-completed.status' | translate" nzShowIcon></nz-alert>
    </p>
  </div>
  <div *ngIf="assessment && assessment.status === MaturityAssessmentStatus.COMPLETED_WITH_RESULT" nz-col nzSpan="24">
    <p>
      <nz-alert nzType="info" [nzMessage]="'mp.ma-completed-with-report.status' | translate" nzShowIcon></nz-alert>
    </p>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24" *ngIf="assessment?.resultFile">
    <p style="width: 100%; text-align: center">
      <a nz-button nzType="primary" [href]="assessment?.resultFile" target="_blank">
        {{ "admin.assessment-download-report.tooltip" | translate }}
      </a>
    </p>
  </div>
</div>

<nz-divider
  *ngIf="
    assessment &&
    assessment.status !== MaturityAssessmentStatus.ACTIVE &&
    (assessment.hashtags.hashtag1 || assessment.hashtags.hashtag2 || assessment.hashtags.hashtag3)
  "
  nzPlain
  [nzText]="'assessment.hashtags.title' | translate"
  nzOrientation="left"
></nz-divider>
<div *ngIf="assessment && assessment.status !== MaturityAssessmentStatus.ACTIVE" nz-row style="margin-bottom: 16px">
  <div nz-col nzSpan="24">
    <nz-tag *ngIf="assessment?.hashtags?.hashtag1">{{ assessment.hashtags.hashtag1 }}</nz-tag>
    <nz-tag *ngIf="assessment?.hashtags?.hashtag2">{{ assessment.hashtags.hashtag2 }}</nz-tag>
    <nz-tag *ngIf="assessment?.hashtags?.hashtag3">{{ assessment.hashtags.hashtag3 }}</nz-tag>
  </div>
</div>

<nz-divider
  *ngIf="assessment"
  nzPlain
  [nzText]="'assessment.facts-and-figures.title' | translate"
  nzOrientation="left"
></nz-divider>
<div nz-row>
  <div nz-col nzSpan="24">
    <form
      *ngIf="assessment && assessment.status === MaturityAssessmentStatus.ACTIVE"
      nz-form
      [formGroup]="factsAndFiguresForm"
      nzLayout="vertical"
      class="facts-and-figures-form"
    >
      <nz-descriptions nzBordered [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">
        <nz-descriptions-item [nzTitle]="'assessment.staffTurnoverPercentage.label' | translate">
          <nz-input-number
            type="number"
            formControlName="staffTurnoverPercentage"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
          />
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.averageAge.label' | translate">
          <nz-input-number type="number" formControlName="averageAge" />
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.averageLengthOfService.label' | translate">
          <nz-input-number type="number" formControlName="averageLengthOfService" />
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.partTimeEmployeesPercentage.label' | translate">
          <nz-input-number
            type="number"
            formControlName="partTimeEmployeesPercentage"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
          />
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.partTimeEmployeesInManagementPercentage.label' | translate">
          <nz-input-number
            type="number"
            formControlName="partTimeEmployeesInManagementPercentage"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
          />
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.womenProportion.label' | translate">
          <nz-input-number
            type="number"
            formControlName="womenProportion"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
          />
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.womenInManagementPercentage.label' | translate">
          <nz-input-number
            type="number"
            formControlName="womenInManagementPercentage"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
          />
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-form-item>
        <nz-form-control style="width: 100%; text-align: center; margin-top: 16px">
          <button nz-button nzType="primary" (click)="saveFactsAndFigures()">
            {{ "save.button" | translate }}
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>

    <div *ngIf="assessment && assessment.status !== MaturityAssessmentStatus.ACTIVE" class="p-t-10">
      <nz-descriptions nzBordered [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">
        <nz-descriptions-item [nzTitle]="'assessment.staffTurnoverPercentage.label' | translate">
          {{ factsAndFigures?.staffTurnoverPercentage }}%
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.averageAge.label' | translate">
          {{ factsAndFigures?.averageAge }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.averageLengthOfService.label' | translate">
          {{ factsAndFigures?.averageLengthOfService }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.partTimeEmployeesPercentage.label' | translate">
          {{ factsAndFigures?.partTimeEmployeesPercentage }}%
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.partTimeEmployeesInManagementPercentage.label' | translate">
          {{ factsAndFigures?.partTimeEmployeesInManagementPercentage }}%
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.womenProportion.label' | translate">
          {{ factsAndFigures?.womenProportion }}%
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="'assessment.womenInManagementPercentage.label' | translate">
          {{ factsAndFigures?.womenInManagementPercentage }}%
        </nz-descriptions-item>
      </nz-descriptions>
    </div>
  </div>
</div>

<nz-divider
  *ngIf="assessment"
  nzPlain
  [nzText]="'assessment.participants.title' | translate"
  nzOrientation="left"
></nz-divider>
<div nz-row>
  <div *ngIf="assessment" class="p-t-10" nz-col nzSpan="24">
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} of {{ total }} items
    </ng-template>
    <nz-table
      #basicTable
      [nzFrontPagination]="true"
      [nzPageSize]="10"
      [nzShowTotal]="rangeTemplate"
      [nzHideOnSinglePage]="true"
      [nzData]="assessment.maturityAssessmentParticipants"
    >
      <thead>
        <tr>
          <th>{{ "mp.ma-participant.label" | translate }}</th>
          <th>{{ "mp.ma-started.label" | translate }}</th>
          <th>{{ "mp.ma-completed.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.personNum }}</td>
          <td>{{ data.startedTimestamp | date : "dd.MM.YYYY HH:mm" }}</td>
          <td>
            <ng-container *ngIf="data.endedTimestamp">{{
              data.endedTimestamp | date : "dd.MM.YYYY HH:mm"
            }}</ng-container>
            <ng-container *ngIf="!data.endedTimestamp"> - </ng-container>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
