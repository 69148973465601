import { UntypedFormGroup } from "@angular/forms";

export enum FiltersPage {
  HIRING_DASHBOARD = "hiringDashboardForm",
  MISSION_PARTNER_LIST = "missionPartnerListForm",
  TALENT_LIST = "talentListForm",
  ADMIN_LIST = "adminListForm",
  JOB_OFFER_LIST = "jobOfferListForm",
  JOB_OFFER_MATCHES = "jobOfferMatchesForm",
  ADMIN_JOB_OFFER_MATCHES = "adminJobOfferMatchesForm",
  INVOICES = "invoicesForm",
  ACTIVE_CONVERSATIONS = "activeConversationsForm",
  NEGOTIATIONS = "negotiationsForm",
}

export interface FilterStorage {
  [FiltersPage.HIRING_DASHBOARD]: UntypedFormGroup;
  [FiltersPage.MISSION_PARTNER_LIST]: UntypedFormGroup;
  [FiltersPage.TALENT_LIST]: UntypedFormGroup;
  [FiltersPage.ADMIN_LIST]: UntypedFormGroup;
  [FiltersPage.JOB_OFFER_LIST]: JobOfferListForm;
  [FiltersPage.JOB_OFFER_MATCHES]: UntypedFormGroup;
  [FiltersPage.ADMIN_JOB_OFFER_MATCHES]: UntypedFormGroup;
  [FiltersPage.INVOICES]: InvoiceForm;
  [FiltersPage.ACTIVE_CONVERSATIONS]: UntypedFormGroup;
  [FiltersPage.NEGOTIATIONS]: UntypedFormGroup;
}

export interface InvoiceForm extends UntypedFormGroup {
  searchTerm?: string | null;
  missionPartnerId?: string | null;
}

export interface JobOfferListForm extends UntypedFormGroup {
  searchTerm?: string | null;
  missionPartnerId?: string | null;
  archived?: boolean;
}
