<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input
            type="text"
            formControlName="searchTerm"
            nz-input
            [placeholder]="'admin.filter.search.placeholder' | translate"
          />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select
          class="mission-partner-filter"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.mission-partner.placeholder' | translate"
          formControlName="missionPartnerId"
        >
          <nz-option
            *ngFor="let option of missionPartnersWithActiveJobOffers"
            [nzLabel]="option.name"
            [nzValue]="option.id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <!-- mission partner for -->
      <nz-form-control>
        <nz-select
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.for-mission-partner.placeholder' | translate"
          formControlName="forMissionPartnerId"
        >
          <nz-option
            *ngFor="let option of missionPartnersWithActiveJobOffers"
            [nzLabel]="option.name"
            [nzValue]="option.id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control>
        <label nz-checkbox formControlName="archived">
          <span>Archived</span>
        </label>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button
          nz-button
          nzBlock
          nzType="default"
          type="button"
          [disabled]="isApiLoading"
          (click)="downloadJobOfferListCSV()"
        >
          <span nz-icon [nzType]="isApiLoading ? 'loading' : 'download'" nzTheme="outline"></span>
          {{ "admin.download.csv" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" [disabled]="!searchForm.valid">
          {{ "admin.filter.submit" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" type="button" (click)="openCreateJobOfferModal()">
          {{ "admin.job-offer.add.label" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="jobOfferList?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="jobOfferList?.paging?.items || 0"
  [nzPageSize]="jobOfferList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="jobOfferList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
    <tr>
      <th nzColumnKey="NAME" [nzSortFn]="true">{{ "admin.name.title" | translate }}</th>
      <th>{{ "admin.mission-partner.title" | translate }}</th>
      <th [nzWidth]="'200px'">{{ "admin.for-mission-partner.title" | translate }}</th>
      <th [nzWidth]="'200px'">{{ "admin.stage.title" | translate }}</th>
      <th>{{ "admin.domain-expertise.title" | translate }}</th>
      <th>{{ "admin.salary.title" | translate }}</th>
      <th>{{ "admin.matched-amount.title" | translate }}</th>
      <th nzColumnKey="REG_TIMESTAMP" [nzSortFn]="true" nzSortOrder="descend">
        {{ "admin.creation-timestamp.title" | translate }}
      </th>
      <th>{{ "admin.action.title" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>
        <nz-spin *ngIf="data.status === JobOpportunitySearchResultStatus.PENDING" nzSimple [nzSize]="'small'"></nz-spin>
        <a [routerLink]="['/admin/job-offer-matches', data.id]" target="_blank">
          {{ data.name }}
        </a>
      </td>
      <td>
        <a [routerLink]="['/admin/mission-partner-view', data.missionPartnerId]" target="_blank">
          {{ data.missionPartnerName }}
        </a>
      </td>

      <td>
        <nz-select
          nzShowSearch
          [nzPlaceHolder]="'admin.for-mission-partner.placeholder' | translate"
          [ngModel]="data.forMissionPartnerId"
          (ngModelChange)="setJobOpportunityForMissionPartner(data.id, $event)"
          style="width: 100%"
        >
          <nz-option *ngFor="let option of missionPartners" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
        </nz-select>
      </td>

      <td>
        <nz-select
          nzShowSearch
          [nzPlaceHolder]="'admin.stage.placeholder' | translate"
          [ngModel]="data.stage"
          (ngModelChange)="setJobOpportunityStage(data.id, $event)"
          style="width: 100%"
        >
          <nz-option
            *ngFor="let stage of jobOpportunityStages$ | async"
            [nzLabel]="stage.name | translate"
            [nzValue]="stage.value"
          ></nz-option>
        </nz-select>
      </td>
      <td>
        <mh-tags-output [value]="data.domainExpertise"></mh-tags-output>
      </td>
      <td>{{ data.salaryExpectations?.min?.name }} - {{ data.salaryExpectations?.max?.name }}</td>
      <td>{{ data.matchedAmount }}</td>
      <td>
        <div class="nowrap">{{ data.creationTimestamp | date : "yyyy-MM-dd" }}</div>
        <div class="nowrap">{{ data.creationTimestamp | date : "HH:mm:ss" }}</div>
      </td>
      <td class="nowrap">
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.sign-in-as.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="onSignInAs(data.missionPartnerId)"
        >
          <span nz-icon nzType="login" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="data.id"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="copyId(data.id)"
        >
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </button>

        <button
          [disabled]="data.archived"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="(data.archived ? 'archived.label' : 'archive.button') | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="showArchiveConfirm(data)"
        >
          <span nz-icon nzType="container" nzTheme="outline"></span>
        </button>
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'Edit'"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="editJobOffer(data.id)"
        >
          <span nz-icon nzType="edit" nzTheme="outline"></span>
        </button>
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'delete.button' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="showDeleteConfirm(data)"
        >
          <span nz-icon nzType="delete" nzTheme="outline"></span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
