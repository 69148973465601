import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { AccountFilter, AccountId, AdminAccount } from "../../home-admin-data/account.types";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountService } from "../../home-admin-data/account.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { take } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { AccountPasswordDialogComponent } from "../../../../../../../common-ui/src/lib/account-password-dialog/account-password-dialog.component";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { AdminUserAdminCreateComponent } from "./admin-user-admin-create/admin-user-admin-create.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { AdminUserAdminEditComponent } from "./admin-user-admin-edit/admin-user-admin-edit.component";

const FILTERS_PAGE = FiltersPage.ADMIN_LIST;

@Component({
  selector: "mh-admin-user-admin-list",
  templateUrl: "./admin-user-admin-list.component.html",
  styleUrls: ["./admin-user-admin-list.component.less"],
})
export class AdminUserAdminListComponent implements OnInit {
  accountList?: PaginatedContent<AdminAccount>;
  loading = true;
  searchForm!: UntypedFormGroup;
  preventDoubleFetch = true;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService,
    private clipboard: Clipboard,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
    });

    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE];
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }

    this.fetchAccounts({ ...this.getDefaultQueryParams(), ...this.getFilterFormValues() });
  }

  fetchAccounts(filter: AccountFilter) {
    this.loading = true;
    this.accountService
      .loadUserAdminList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.accountList = paginatedContent;
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const { pageSize, pageIndex, sort, filter } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.accountList?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");

    return {
      searchTerm: searchField?.value || "",
    };
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  sendPasswordResetEmail(accountId: AccountId, email: string) {
    this.accountService.sendPasswordResetEmail(accountId, email).subscribe((success) => {
      if (success) {
        this.notificationService.success("", this.translateService.instant("admin.password-reset.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  openSetPasswordModal(account: AdminAccount) {
    const modal = this.modal.create({
      nzContent: AccountPasswordDialogComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { account },
    });
  }

  openEditModal(admin: AdminAccount) {
    const modal = this.modal.create({
      nzContent: AdminUserAdminEditComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { admin },
    });

    modal.afterClose.subscribe((result) => {
      if (result === "success") {
        this.fetchAccounts({ ...this.getDefaultQueryParams(), ...this.getFilterFormValues() });
      }
    });
  }

  openCreateAdminModal() {
    const modal = this.modal.create({
      nzContent: AdminUserAdminCreateComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
    });

    modal.afterClose.subscribe((result: string) => {
      if (result === "success") {
        this.submitFilterForm();
      }
    });
  }

  deleteAccount(accountId: AccountId, accountType = AccountType.ADMIN) {
    this.accountService.deleteAccount(accountId, accountType).subscribe((success) => {
      if (success) {
        if (this.accountList?.items) {
          this.accountList.items = this.accountList.items.filter(
            (account) => account.accountId.internalId !== accountId.internalId,
          );
        }
        this.notificationService.success("", this.translateService.instant("admin.account-delete.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  showDeleteConfirm(account: AdminAccount) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("admin.account-delete.confirm.title"),
      nzContent: `<b>${this.translateService.instant("admin.account-delete.confirm.text")}</b>`,
      nzOkText: this.translateService.instant("admin.account-delete.confirm.ok.btn"),
      nzCancelText: this.translateService.instant("admin.account-delete.confirm.cancel.btn"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteAccount(account.accountId),
    });
  }

  copyId(id: string) {
    const isCopySuccessful = this.clipboard.copy(id);
    if (isCopySuccessful) {
      this.notificationService.success("", this.translateService.instant("admin.copy-id.success"));
    }
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchAccounts(filter);
  }

  getDefaultQueryParams() {
    return {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: "",
    };
  }
}
