<ng-container *nzModalTitle>
  {{ "admin.match-criteria.modal.title" | translate }}
</ng-container>

<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzSpan]="24">{{ "admin.manual-match.modal.mission-partner" | translate }}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'field.required.label' | translate }}">
      <nz-select formControlName="missionPartnerId" nzShowSearch (ngModelChange)="onMissionPartnerChange($event)">
        <nz-option *ngFor="let mp of missionPartners" [nzValue]="mp.id" [nzLabel]="mp.name"> </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">{{ "admin.manual-match.modal.job-offer" | translate }}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'field.required.label' | translate }}">
      <nz-select
        formControlName="jobOfferId"
        [nzLoading]="isLoadingJobOffers"
        nzShowSearch
        (ngModelChange)="onJobOfferChange($event)"
      >
        <nz-option *ngFor="let jo of jobOffers" [nzValue]="jo.id" [nzLabel]="jo.name"> </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="matchExplanation">
    <nz-form-label [nzSpan]="24">{{ "admin.match-criteria.explanation" | translate }}</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-spin *ngIf="isLoadingExplanation"></nz-spin>
      <div *ngIf="!isLoadingExplanation" class="match-explanation">
        {{ matchExplanation }}
      </div>
    </nz-form-control>
  </nz-form-item>
</form>

<div *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">
    {{ "close.button" | translate }}
  </button>
  <button nz-button nzType="primary" (click)="onOk()">
    {{ "check.button" | translate }}
  </button>
</div>
