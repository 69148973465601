import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { JobOffer } from "../../../../../api/job-offer/job-offer.types";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { CmsNameValue, compareCmsNameValue } from "../../../../../api/cms/cms.types";
import { TranslateService } from "@ngx-translate/core";
import { CmsCachedService } from "../../../../../api/cms/cms-cached.service";

@Component({
  selector: "mh-mp-job-offer-form",
  templateUrl: "./mp-job-offer-form.component.html",
  styleUrls: ["./mp-job-offer-form.component.less"],
})
export class MpJobOfferFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() jobOffer!: JobOffer;
  @Input() isMobile: boolean | null = false;
  @Output() formChange = new EventEmitter<JobOffer>();

  formGroup!: UntypedFormGroup;
  private readonly destroy$ = new Subject<void>();

  public workAssignment$: Observable<Array<CmsNameValue>>;
  public expertFields$: Observable<Array<CmsNameValue>>;
  public sectors$: Observable<Array<CmsNameValue>>;
  public languageOptions$: Observable<Array<CmsNameValue>>;
  public industry$: Observable<Array<CmsNameValue>>;
  public careerLevel$: Observable<Array<CmsNameValue>>;
  public spokenLanguages$: Observable<Array<CmsNameValue>>;
  public degree$: Observable<Array<CmsNameValue>>;

  get locationsControl(): AbstractControl | null {
    return this.formGroup.get("locations");
  }

  get countryControl(): AbstractControl | null {
    return this.formGroup.get("countryCode");
  }

  get experienceYears(): string {
    const exp = this.formGroup.get("workExperience")?.value || 0;
    return this.numToYearString(exp, 30);
  }

  get leadershipYears(): string {
    const exp = this.formGroup.get("leadershipExperience")?.value || 0;
    return this.numToYearString(exp, 30);
  }

  private numToYearString(value: number, max = 10) {
    let suffix = value == 1 ? "year.label" : "years.label";
    suffix = this.translateService.instant(suffix);
    return "<b>" + (value == max ? value + "+" : value) + "</b>" + " " + suffix;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private readonly translateService: TranslateService,
    private cmsCachedService: CmsCachedService,
  ) {
    this.workAssignment$ = this.cmsCachedService.getUserDataByName("ideal-working-assignment");
    this.expertFields$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.sectors$ = this.cmsCachedService.getUserDataByName("sector");
    this.languageOptions$ = this.cmsCachedService.getUserDataByName("preferred-languages");
    this.industry$ = this.cmsCachedService.getUserDataByName("industry");
    this.careerLevel$ = this.cmsCachedService.getUserDataByName("career-level", false);
    this.spokenLanguages$ = this.cmsCachedService.getUserDataByName("language");
    this.degree$ = this.cmsCachedService.getUserDataByName("degree");
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: [this.jobOffer?.name, [Validators.required]],
      description: [this.jobOffer?.description, [Validators.required]],
      salaryExpectations: [this.jobOffer?.salaryExpectations || {}, [Validators.required]],
      locations: [this.jobOffer?.locations || [], [Validators.required]],
      mode: [this.jobOffer?.mode, [Validators.required]],
      jobSharing: [this.jobOffer?.jobSharing, [Validators.required]],
      domainExpertise: [this.jobOffer?.domainExpertise, [Validators.required]],
      domainPreference: [this.jobOffer?.domainPreference, [Validators.required]],
      workExperience: [this.jobOffer?.workExperience, [Validators.required]],
      leadershipExperience: [this.jobOffer?.leadershipExperience, [Validators.required]],
      leadershipTeamSize: [this.jobOffer?.leadershipTeamSize, [Validators.required]],
      sectorFocus: [this.jobOffer?.sectorFocus, [Validators.required]],
      workLanguage: [this.jobOffer?.workLanguage, [Validators.required]],
      spokenLanguages: [this.jobOffer?.spokenLanguages, []],
      industries: [this.jobOffer?.industries, []],
      careerLevel: [this.jobOffer?.careerLevel, []],
      degree: [this.jobOffer?.degree, []],
      countryCode: [this.jobOffer?.countryCode, []],
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: Partial<JobOffer>) => {
      this.updateLocationsControl(value);
      this.formChange.next({ ...this.jobOffer, ...value });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.jobOffer) {
      setTimeout(() => this.updateLocationsControl(this.jobOffer), 0);
    }
  }

  isValid(): boolean {
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });

      const salaryExpectationsControl = this.formGroup.get("salaryExpectations");
      if (salaryExpectationsControl) {
        salaryExpectationsControl.markAsDirty();
        salaryExpectationsControl.updateValueAndValidity({ onlySelf: true });
      }
    }
    return this.formGroup.valid;
  }

  protected updateLocationsControl(value: Partial<JobOffer>) {
    if (value?.countryCode) {
      this.countryControl?.setValidators([]);
      this.locationsControl?.setValidators([]);
      this.locationsControl?.disable({ onlySelf: true, emitEvent: false });
    } else if (this.locationsControl?.enabled && !this.locationsControl?.value.length) {
      this.countryControl?.setValidators([Validators.required]);
    } else {
      this.countryControl?.setValidators([]);
      this.locationsControl?.enable({ onlySelf: true, emitEvent: false });
      this.locationsControl?.setValidators([Validators.required]);
    }
    this.countryControl?.updateValueAndValidity({ onlySelf: true });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
