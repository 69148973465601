<ng-container *nzModalTitle>
  {{ "admin.user-admin.edit.title" | translate }}
</ng-container>

<form nz-form [formGroup]="validateForm">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired nzFor="name">{{
      "admin.user-admin.create.name.label" | translate
    }}</nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="'validation.required' | translate">
      <input
        nz-input
        formControlName="name"
        id="name"
        [placeholder]="'admin.user-admin.create.name.placeholder' | translate"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired nzFor="email">{{
      "admin.user-admin.create.email" | translate
    }}</nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="'validation.required' | translate">
      <input
        nz-input
        formControlName="email"
        id="email"
        type="email"
        [placeholder]="'admin.user-admin.create.email.placeholder' | translate"
      />
    </nz-form-control>
  </nz-form-item>
</form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">{{ "cancel.button" | translate }}</button>
  <button nz-button nzType="primary" [disabled]="validateForm?.invalid" (click)="onSave()">
    {{ "save.button" | translate }}
  </button>
</ng-container>
