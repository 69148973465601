import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../../../home-admin-data/account.service";
import { AdminAccount } from "../../../home-admin-data/account.types";

@Component({
  selector: "mh-admin-user-admin-edit",
  templateUrl: "./admin-user-admin-edit.component.html",
})
export class AdminUserAdminEditComponent implements OnInit {
  validateForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private modal: NzModalRef,
    private accountService: AccountService,
    private notificationService: NzNotificationService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const admin = this.modal.getConfig().nzData?.admin as AdminAccount;
    if (!admin) {
      this.notificationService.error("", this.translateService.instant("notification.error"));
      this.modal.close();
      return;
    }

    this.validateForm = this.fb.group({
      name: [admin.firstName, [Validators.required]],
      email: [admin.email, [Validators.required, Validators.email]],
    });
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(): void {
    if (this.validateForm?.valid) {
      const admin = this.modal.getConfig().nzData?.admin as AdminAccount;
      const formValue = this.validateForm.value;
      this.accountService.updateAdmin(admin.email, formValue.email, formValue.name).subscribe((success) => {
        if (success) {
          this.notificationService.success("", this.translateService.instant("admin.user-admin.edit.success"));
          this.modal.close("success");
        } else {
          this.notificationService.error("", this.translateService.instant("notification.error"));
        }
      });
    } else {
      Object.values(this.validateForm?.controls || {}).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
