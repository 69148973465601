<div class="hiring-dashboard">
  <form class="admin-filters" nz-form [formGroup]="filterForm" (ngSubmit)="submitFilterForm()">
    <div class="admin-filters__fields">
      <nz-form-item class="admin-filters__field">
        <nz-form-control>
          <nz-select
            formControlName="missionPartnerId"
            [nzPlaceHolder]="'admin.manual-match.modal.mission-partner' | translate"
            nzAllowClear
            nzShowSearch
          >
            <nz-option *ngFor="let partner of missionPartners" [nzValue]="partner.id" [nzLabel]="partner.name">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="admin-filters__field">
        <nz-form-control>
          <nz-select
            formControlName="jobOpportunityId"
            [nzPlaceHolder]="'admin.manual-match.modal.job-offer' | translate"
            [nzDisabled]="!filterForm.get('missionPartnerId')?.value"
            nzAllowClear
            nzShowSearch
          >
            <nz-option *ngFor="let job of jobOpportunities" [nzValue]="job.id" [nzLabel]="job.name"> </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="admin-filters__actions">
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary" [disabled]="!filterForm.valid">
            {{ "admin.filter.submit" | translate }}
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>

  <nz-spin [nzSpinning]="loading">
    <div class="hiring-dashboard__columns" *ngIf="dashboardData" cdkDropListGroup>
      <div
        class="hiring-dashboard__column"
        *ngFor="let column of dashboardData.columns"
        cdkDropList
        [cdkDropListData]="column.talents"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListSortPredicate]="sortPredicate"
        cdkDropListSortingDisabled
      >
        <div class="hiring-dashboard__column-header">
          <h3>{{ column.name }}</h3>
          <span class="hiring-dashboard__column-count">{{ column.talents.length }}</span>
        </div>
        <div class="hiring-dashboard__column-content">
          <div
            class="hiring-dashboard__item"
            *ngFor="let talent of column.talents"
            cdkDrag
            [cdkDragData]="{ talent: talent, columnId: column.id }"
            noReturnTransition
          >
            <div class="hiring-dashboard__item-talent">
              <a [routerLink]="['/admin/talent-view', talent.accountId]" target="_blank">
                {{ talent.talentName }}
              </a>
            </div>
            <div class="hiring-dashboard__item-job">
              <a [routerLink]="['/admin/job-offer-matches', talent.jobOpportunityId]" target="_blank">
                {{ talent.jobOpportunityName }}
              </a>
            </div>
            <div class="hiring-dashboard__item-footer">
              <div
                class="hiring-dashboard__item-date"
                *ngIf="talent.reminderDate"
                [class.past-date]="isPastDate(talent.reminderDate)"
              >
                {{ talent.reminderDate | date : "dd.MM.yyyy" }}
              </div>
              <button
                nz-button
                nzType="link"
                class="hiring-dashboard__reminder-btn"
                (click)="openReminderDialog(talent)"
              >
                <span nz-icon [nzType]="talent.reminderDate ? 'bell' : 'plus'" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
