import { Inject, Injectable, Optional, SkipSelf } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppConfig } from "../app-config/config.types";
import { APP_CONFIGURATION } from "../core.di";
import { en_US, de_DE, NzI18nService } from "ng-zorro-antd/i18n";
import { enGB, de } from "date-fns/locale";

export const LOCALSTORAGE_LOCALE_KEY = "selectedLocale";

@Injectable({
  providedIn: "root",
})
export class LocaleService {
  private initialized = false;

  get currentLocale(): string {
    return this.appConfig.appLocales.includes(this.translate.currentLang)
      ? this.translate.currentLang
      : this.appConfig.defaultLocale;
  }

  constructor(
    private translate: TranslateService,
    @Optional()
    @SkipSelf()
    otherInstance: LocaleService,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private i18n: NzI18nService,
  ) {
    if (otherInstance) throw "LocaleService should have only one instance.";
  }

  initLocale(localeId: string, defaultLocaleId = localeId) {
    if (this.initialized) return;
    this.initialized = true;
    this.setDefaultLocale(defaultLocaleId);
    return this.setLocale(localeId);
  }

  setDefaultLocale(localeId: string) {
    this.translate.setDefaultLang(localeId);
  }

  setLocale(localeId: string) {
    localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, localeId);
    switch (localeId) {
      case "en":
        this.i18n.setLocale(en_US);
        this.i18n.setDateLocale(enGB);
        break;
      case "de":
        this.i18n.setLocale(de_DE);
        this.i18n.setDateLocale(de);
        break;
      default:
        this.i18n.setLocale(de_DE);
        this.i18n.setDateLocale(de);
        break;
    }
    return this.translate.use(localeId);
  }

  getSelectedLocale(): string | null {
    return localStorage.getItem(LOCALSTORAGE_LOCALE_KEY);
  }
}
