import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import {
  TalentAccount,
  MissionPartnerBasicInfo,
} from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";

@Component({
  selector: "mh-match-criteria-dialog",
  templateUrl: "./match-criteria-dialog.component.html",
  styleUrls: ["./match-criteria-dialog.component.less"],
})
export class MatchCriteriaDialogComponent implements OnInit {
  talent: TalentAccount;
  form!: UntypedFormGroup;
  missionPartners: MissionPartnerBasicInfo[] = [];
  jobOffers: any[] = [];
  isLoadingJobOffers = false;
  matchExplanation = "";
  isLoadingExplanation = false;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
  ) {
    this.talent = this.modalData["talent"];
    this.form = this.fb.group({
      missionPartnerId: [null, [Validators.required]],
      jobOfferId: [{ value: null, disabled: true }, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.loadMissionPartners();
  }

  loadMissionPartners() {
    this.accountService.getMissionPartnersWithActiveJobOffers().subscribe((data) => {
      this.missionPartners = data;
    });
  }

  onMissionPartnerChange(mpId: string) {
    if (!mpId) {
      this.form.get("jobOfferId")?.disable();
      this.jobOffers = [];
      this.matchExplanation = "";
      return;
    }

    this.isLoadingJobOffers = true;
    this.form.get("jobOfferId")?.enable();
    this.form.get("jobOfferId")?.setValue(null);

    this.accountService.getJobOpportunitiesForMissionPartner(mpId).subscribe({
      next: (jobOffers) => {
        this.jobOffers = jobOffers || [];
        this.isLoadingJobOffers = false;
      },
      error: () => {
        this.isLoadingJobOffers = false;
        this.jobOffers = [];
      },
    });
  }

  onJobOfferChange(jobOfferId: string) {
    if (!jobOfferId) {
      this.matchExplanation = "";
    }
  }

  getMatchCriteriaExplanation(jobOfferId: string) {
    if (!jobOfferId) {
      return;
    }

    this.isLoadingExplanation = true;
    this.matchExplanation = "";

    this.accountService.getMatchCriteriaExplanation(jobOfferId, this.talent.accountId.internalId).subscribe({
      next: (explanation) => {
        this.matchExplanation = explanation || "";
        this.isLoadingExplanation = false;
      },
      error: (err) => {
        console.error("Error fetching match criteria explanation:", err);
        this.matchExplanation = "admin.match-criteria.error";
        this.isLoadingExplanation = false;
      },
    });
  }

  onCancel(): void {
    this.modal.close();
  }

  onOk(): void {
    const jobOfferId = this.form.get("jobOfferId")?.value;
    this.getMatchCriteriaExplanation(jobOfferId);
  }
}
