import { Component, Inject, OnInit } from "@angular/core";
import { AccountService } from "../../home-admin-data/account.service";
import { take } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Talent } from "../../../../api/sign-up/signup.types";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { DocumentService } from "../../../../api/document/document.service";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Reference, ReferenceRequest } from "../../../../api/references/references.types";
import { CommonService } from "../../../../api/common.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { AdminJobOfferService } from "../../../../api/admin-job-offer/admin-job-offer.service";
import { TalentStages } from "../../../../api/job-offer/job-offer.types";
import { CmsNameValue } from "../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../api/cms/cms-cached.service";

@Component({
  selector: "mh-admin-talent-view",
  templateUrl: "./admin-talent-view.component.html",
  styleUrls: ["./admin-talent-view.component.less"],
})
export class AdminTalentViewComponent implements OnInit {
  talentId = "";
  talent: Talent | undefined;
  avatarUrl = "";
  cvList: NzUploadFile[] = [];
  fileList: NzUploadFile[] = [];
  references: Reference[] = [];
  referencesRequest: ReferenceRequest[] = [];
  talentStages: TalentStages[] = [];
  talentStageOptions: CmsNameValue[] = [];

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private documentsService: DocumentService,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private commonService: CommonService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private adminJobOfferService: AdminJobOfferService,
    private cmsCachedService: CmsCachedService,
  ) {}

  ngOnInit() {
    const accountId = this.route.snapshot.paramMap.get("id");
    if (accountId) {
      this.talentId = accountId;
      this.accountService
        .getTalentProfile(this.talentId)
        .pipe(take(1))
        .subscribe((talent) => {
          if (talent) {
            this.talent = talent;
            this.avatarUrl = this.commonService.toAbsUrl(talent.avatar?.avatarUrl);
          }
        });

      this.documentsService.loadUserCV(this.talentId).subscribe((list) => (this.cvList = list));
      this.documentsService.loadAllUserFiles(this.talentId).subscribe((list) => (this.fileList = list));

      this.accountService
        .loadReferencesById(this.talentId)
        .pipe(take(1))
        .subscribe((references) => {
          this.references = references;
        });
      this.accountService
        .loadReferencesRequestById(this.talentId)
        .pipe(take(1))
        .subscribe((referencesRequest) => {
          this.referencesRequest = referencesRequest;
        });

      this.adminJobOfferService
        .getTalentStages(this.talentId)
        .pipe(take(1))
        .subscribe((stages) => {
          this.talentStages = stages;
        });

      this.cmsCachedService
        .getUserDataByName("jo-cm-stage")
        .pipe(take(1))
        .subscribe((stages) => {
          this.talentStageOptions = stages;
        });
    }
  }

  onTalentUpdate(talent: Talent) {
    this.accountService.updateTalent(talent).subscribe((result) => {
      if (result) this.notificationService.success("", this.translateService.instant("profile.updated.notification"));
    });
  }

  onSignInAs() {
    this.accountService.signInAs(this.talentId);
  }

  onSetTalentStage(jobOpportunityId: string, stage: number) {
    this.adminJobOfferService.setTalentStage(jobOpportunityId, this.talentId, stage).subscribe((success) => {
      if (success) {
        if (stage === 0) {
          this.talentStages = this.talentStages.filter((ts) => ts.jobOpportunityId !== jobOpportunityId);
        } else {
          const talentStage = this.talentStages.find((ts) => ts.jobOpportunityId === jobOpportunityId);
          if (talentStage) {
            talentStage.stage = stage;
          }
        }
      }
    });
  }

  protected readonly AccountType = AccountType;
}

// EN
// admin.talent-view.section.title.talent-stages=Career Mom
// admin.talent-view.talent-stages.job-opportunity=Job Opportunity
// admin.talent-view.talent-stages.stage=Stage

// DE
// admin.talent-view.section.title.talent-stages=
// admin.talent-view.talent-stages.job-opportunity=
// admin.talent-view.talent-stages.stage=
