import { Inject, Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map, Observable } from "rxjs";
import {
  TalentAccountDTO,
  MissionPartnerAccountDTO,
  AccountFilterDTO,
  AccountId,
  CommentDTO,
  AdminAccountDTO,
  ActiveConversationDTO,
  NegotiationDTO,
  MissionPartnerBasicInfo,
  CommentFromJoDTO,
  JobOpportunityBasicInfo,
  HiringDashboardData,
} from "./account.types";
import { BaseApi } from "../../../api/base-api";
import { APP_CONFIGURATION } from "../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../core/src/lib/app-config/config.types";
import {
  ApiPaginatedResponseDTO,
  ApiResponseDTO,
  initialPaginatedContent,
  PaginatedContent,
} from "../../../api/common.types";
import { AccountType } from "../../login-page/login-page-data/login.types";
import { saveAs } from "file-saver";
import {
  MissionPartnerWithDataSpaceDTO,
  TalentDTO,
  TalentWithDataSpaceDTO,
} from "../../../api/sign-up/signup-api.types";
import { ReferenceDTO, ReferenceRequestDTO } from "../../../api/references/references-api.types";
import { JobOfferDTO, JobOfferTalentCommentDTO, MatchedTalentDTO } from "../../../api/job-offer/job-offer.types";
import { AgreementTerms } from "../../../api/messages/messages.types";

@Injectable({ providedIn: "root" })
export class AccountApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadTalentList(filter: AccountFilterDTO): Observable<PaginatedContent<TalentAccountDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<TalentAccountDTO>>(`${this.basePath}/admin/user/talent/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadMissionPartnerList(filter: AccountFilterDTO): Observable<PaginatedContent<MissionPartnerAccountDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<MissionPartnerAccountDTO>>(`${this.basePath}/admin/user/mission-partner/list`, {
        ...filter,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadUserAdminList(filter: AccountFilterDTO): Observable<PaginatedContent<AdminAccountDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<AdminAccountDTO>>(`${this.basePath}/admin/admin/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadActiveConversationsList(filter: AccountFilterDTO): Observable<PaginatedContent<ActiveConversationDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<ActiveConversationDTO>>(`${this.basePath}/admin/messaging/active-conversations`, {
        ...filter,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadNegotiationsList(filter: AccountFilterDTO): Observable<PaginatedContent<NegotiationDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<NegotiationDTO>>(`${this.basePath}/admin/messaging/negotiations`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadJobOffer(jobOfferId: string): Observable<JobOfferDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  loadJobOfferMatches(filter: AccountFilterDTO): Observable<PaginatedContent<MatchedTalentDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<MatchedTalentDTO>>(`${this.basePath}/admin/job-opportunity/talents/list`, {
        ...filter,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadJobOfferMatchesReindex(jobOfferId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}/talents/reindex`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  sendPasswordResetEmail(accountId: AccountId, email: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/password-forgotten`, { accountId, email })
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  updatePassword(accountId: AccountId, password: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/user/password`, { accountId, password }).pipe(
      map((response) => {
        if (response.success) {
          return true;
        } else {
          super.handleErrorResponse(response);
          return false;
        }
      }),
    );
  }

  deleteAccount(accountId: AccountId, accountType: AccountType): Observable<boolean> {
    return this.httpClient
      .delete<ApiResponseDTO>(`${this.basePath}/admin/user/delete`, {
        body: { accountId, accountType },
        observe: "body",
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  reviewMissionPartner(accountId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/review/${accountId}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  discardReviewMissionPartner(accountId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/discard-review/${accountId}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  setSelfServiceStatusMissionPartner(missionPartnerId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(
        `${this.basePath}/admin/user/mission-partner/set-self-service-status/${missionPartnerId}`,
        {},
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  removeSelfServiceStatusMissionPartner(missionPartnerId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(
        `${this.basePath}/admin/user/mission-partner/remove-self-service-status/${missionPartnerId}`,
        {},
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  setPassiveStatusMissionPartner(missionPartnerId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/set-passive-status/${missionPartnerId}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  removePassiveStatusMissionPartner(missionPartnerId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/remove-passive-status/${missionPartnerId}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  setPremiumStatusMissionPartner(missionPartnerId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/set-premium-status/${missionPartnerId}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  removePremiumStatusMissionPartner(missionPartnerId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/remove-premium-status/${missionPartnerId}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  setStageMissionPartner(missionPartnerId: string, stage: number): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/mission-partner/stage/${missionPartnerId}/${stage}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  downloadData(accountId: AccountId, accountType: AccountType, fileName: string): Observable<void> {
    return this.httpClient
      .get<Blob>(
        `${this.basePath}/admin/user/retrieve-data?accountId=${accountId.internalId}&accountType=${accountType}`,
        {
          observe: "response",
          responseType: "blob" as "json",
        },
      )
      .pipe(
        map((response: any) => {
          saveAs(response.body, fileName);
        }),
      );
  }

  downloadTalentListCSV(fileName: string): Observable<void> {
    return this.httpClient
      .get<Blob>(`${this.basePath}/admin/user/talent/list/csv`, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: any) => {
          saveAs(response.body, fileName);
        }),
      );
  }

  downloadMissionPartnerListCSV(fileName: string): Observable<void> {
    return this.httpClient
      .get<Blob>(`${this.basePath}/admin/user/mission-partner/list/csv`, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: any) => {
          saveAs(response.body, fileName);
        }),
      );
  }

  signInAs(accountId: string): Observable<string> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/admin/user/sign-as/${accountId}`, { observe: "body" })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.token;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  getTalentProfile(accountId: string): Observable<TalentWithDataSpaceDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/user/profile/${accountId}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  getMissionPartnerProfile(accountId: string): Observable<MissionPartnerWithDataSpaceDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/user/profile/${accountId}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  loadAccountComments(accountId: string): Observable<Array<CommentDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/user/${accountId}/comments`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  loadAccountCommentsFromJobOpportunities(accountId: string): Observable<Array<CommentFromJoDTO>> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/admin/user/${accountId}/commentsFromJobOpportunities`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return response;
          }
        }),
      );
  }

  sendAccountComment(targetId: string, text: string, authorId: string): Observable<CommentDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/comments`, {
        targetAccountId: {
          internalId: targetId,
        },
        text,
        authorAccountId: {
          internalId: authorId,
        },
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return response;
          }
        }),
      );
  }

  updateAccountComment(commentId: string, text: string): Observable<CommentDTO> {
    return this.httpClient.put<ApiResponseDTO>(`${this.basePath}/admin/user/comments`, { commentId, text }).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  deleteAccountComment(commentId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/admin/user/comment/${commentId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  loadJobOfferTalentComments(jobOfferId: string, talentId: string): Observable<Array<JobOfferTalentCommentDTO>> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}/talents/${talentId}/comments`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return response;
          }
        }),
      );
  }

  sendJobOfferTalentComment(jobOfferId: string, talentId: string, text: string): Observable<JobOfferTalentCommentDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}/talents/${talentId}/comments`, {
        text,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return response;
          }
        }),
      );
  }

  updateJobOfferTalentComment(commentId: string, text: string): Observable<JobOfferTalentCommentDTO> {
    return this.httpClient
      .put<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/comments`, { commentId, text })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return response;
          }
        }),
      );
  }

  deleteJobOfferTalentComments(jobOfferId: string, commentId: string): Observable<boolean> {
    return this.httpClient
      .delete<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}/comments/${commentId}`)
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  getAdminList(): Observable<AdminAccountDTO[]> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/admin`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
      catchError(super.handleHttpError),
    );
  }

  updateAdmin(oldEmail: string, email: string, name: string): Observable<boolean> {
    return this.httpClient.put<ApiResponseDTO>(`${this.basePath}/admin/admin`, { oldEmail, email, name }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
      catchError(super.handleHttpError),
    );
  }

  createAdmin(email: string, password: string, name: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/admin`, { email, password, name }).pipe(
      map((response) => {
        if (response.success) {
          return true;
        } else {
          super.handleErrorResponse(response);
          return false;
        }
      }),
      catchError(super.handleHttpError),
    );
  }

  updateTalent(talent: TalentDTO): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/user/talent/update`, talent).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  changeInvoiceStatus(invoiceId: string, status: string): Observable<boolean> {
    return this.httpClient
      .put<ApiResponseDTO>(`${this.basePath}/admin/invoice/${invoiceId}/change-status/${status}`, {})
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  exportTalentProfileToBrevo(talentAccountId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/general/synchronize-talent/brevo/${talentAccountId}`, {
        talentAccountId,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  exportMissionPartnerProfileToBrevo(missionPartnerAccountId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(
        `${this.basePath}/admin/general/synchronize-missionpartner/brevo/${missionPartnerAccountId}`,
        {
          missionPartnerAccountId,
        },
      )
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  setCommunityMemberStatus(talentId: string, status: boolean): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/talent/${talentId}/community-member/${status}`, {})
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  addAccountConfirmedStatus(accountId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/user/${accountId}/confirm`, {
        accountId: {
          internalId: accountId,
        },
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  getMissionPartnersWithActiveJobOffers(): Observable<MissionPartnerBasicInfo[]> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/hiring/missionpartnersWithActiveJobOffers`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.missionpartners;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
    );
  }

  getMissionPartners(): Observable<MissionPartnerBasicInfo[]> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/hiring/missionpartners`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.missionpartners;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
    );
  }

  getJobOpportunitiesForMissionPartnerWithoutTalent(
    missionPartnerId: string,
    talentId: string,
  ): Observable<JobOpportunityBasicInfo[]> {
    return this.httpClient
      .get<ApiResponseDTO>(
        `${this.basePath}/admin/hiring/jobOpportunitiesForMissionPartnerWithoutTalent/${missionPartnerId}/${talentId}`,
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.jobOffers;
          } else {
            super.handleErrorResponse(response);
            return [];
          }
        }),
      );
  }

  getJobOpportunitiesForMissionPartner(missionPartnerId: string): Observable<JobOpportunityBasicInfo[]> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/admin/hiring/jobOpportunitiesForMissionPartner/${missionPartnerId}`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.jobOffers;
          } else {
            super.handleErrorResponse(response);
            return [];
          }
        }),
      );
  }

  addTalentToJobOffer(talentId: string, jobopportunityId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/hiring/handpickTalent`, {
        jobopportunityId,
        talentId,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  getHiringDashboard(): Observable<HiringDashboardData> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/hiring/dashboard`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.dashboard;
        } else {
          super.handleErrorResponse(response);
          return { columns: [] };
        }
      }),
    );
  }

  getHiringDashboardWithFilters(missionPartnerId: string, jobOpportunityId: string): Observable<HiringDashboardData> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/hiring/dashboard`, {
        missionPartnerId,
        jobOpportunityId,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.dashboard;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  getHiringTerms(conversationId: string): Observable<AgreementTerms> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/hiring/hiringTerms/${conversationId}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.terms;
        } else {
          super.handleErrorResponse(response);
          return null;
        }
      }),
    );
  }

  setHiringReminder(talentId: string, jobOpportunityId: string, reminderDate: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.basePath}/admin/hiring/reminder`, {
      talentId,
      jobOpportunityId,
      reminderDate,
    });
  }

  deleteHiringReminder(talentId: string, jobOpportunityId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.basePath}/admin/hiring/reminder`, {
      body: { talentId, jobOpportunityId },
    });
  }

  loadReferencesById(accountId: string): Observable<Array<ReferenceDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/user/${accountId}/references`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
    );
  }

  loadReferencesRequestById(accountId: string): Observable<Array<ReferenceRequestDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/user/${accountId}/reference-requests`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
    );
  }

  getMatchCriteriaExplanation(jobOpportunityId: string, accountId: string): Observable<string> {
    return this.httpClient
      .get<ApiResponseDTO>(
        `${this.basePath}/admin/job-opportunity/wouldMatchHardCriteria/${jobOpportunityId}/${accountId}`,
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.explanation;
          } else {
            super.handleErrorResponse(response);
            return "";
          }
        }),
      );
  }
}
